<template>
  <v-app>
    <div
      v-if="$store.state.drawerLoading || $store.state.drawerLoadingAdmin"
      class="overlay d-flex justify-center align-center"
      style="height: 100%"
    >
      <v-progress-circular class="text-center" indeterminate color="primary"  :size="70"
      :width="12" style="position:absolute" >
        <br>
        </v-progress-circular>
        <small class="text-center"  style="margin-top:125px !important;font-size: 18px;">Su consulta se está ejecutando<br>obteniendo datos...</small>

    </div>

    <dashboard-core-app-bar  v-if="!$store.state.drawerLoading && !$store.state.drawerLoadingAdmin"/>

    <dashboard-core-drawer
      v-if="$store.state.keyDrawer > 0 && (!$store.state.drawerLoading && !$store.state.drawerLoadingAdmin)"
      :key="$store.state.keyDrawer"
    />

    <dashboard-core-view v-if="!$store.state.drawerLoading && !$store.state.drawerLoadingAdmin"/>

    <!-- <dashboard-core-settings /> -->
    <!--<chat-button />-->

  </v-app>

</template>

<script>
//import { mapActions } from "vuex";
import { mapState, mapActions } from "vuex";
export default {
  name: "DashboardIndex",

  data: () => ({
    expandOnHover: false,
  }),

  methods: {
    ...mapActions({
      signIn: "login",
      signOut: "logout",
    }),

  },
  created() {
    this.$cv("colorPrimary");
    this.signIn();
    //this.getUser();
  },
  components: {
    DashboardCoreAppBar: () => import("./components/core/AppBar"),
    DashboardCoreDrawer: () => import("./components/core/Drawer"),
    //DashboardCoreSettings: () => import('./components/core/Settings'),
    DashboardCoreView: () => import("./components/core/View"),
    ChatButton: () => import("./components/core/ChatButton.vue"),
  },
};
</script>


<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
</style>
